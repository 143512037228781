var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "router-link",
        {
          attrs: {
            to: {
              name: "EditReportType",
              params: { code: _vm.templateArgs.code, model: _vm.templateArgs },
            },
          },
        },
        [_vm._v(_vm._s(_vm.templateArgs.name))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }