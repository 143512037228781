var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("input", {
      staticClass: "k-checkbox",
      attrs: {
        "data-role": "isPersistent",
        "aria-label": "Is Persistent",
        type: "checkbox",
        disabled: _vm.templateArgs.disabled,
      },
      domProps: { checked: _vm.templateArgs.isPersistent },
      on: {
        click: function ($event) {
          return _vm.savePersistence($event, _vm.templateArgs.code)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }