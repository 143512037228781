var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            "max-width": "100%",
            margin: "auto",
            overflow: "hidden",
          },
          attrs: { id: "body-content-area" },
        },
        [
          _vm.isLoading && _vm.reportTypeItems.length === 0
            ? _c("div", [_c("Loading")], 1)
            : _vm._e(),
          _c(
            "div",
            {
              class: {
                hidden: !_vm.isLoading && _vm.reportTypeItems.length === 0,
              },
              staticStyle: { overflow: "hidden" },
            },
            [
              _c("div", { attrs: { id: "pagelayout" } }, [
                _c(
                  "div",
                  [
                    !_vm.isLoading
                      ? _c(
                          "div",
                          {
                            staticClass: "bottom-bar",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-end",
                              "padding-bottom": "1rem",
                            },
                            attrs: { id: "buttongroup" },
                          },
                          [
                            _c("div", { staticClass: "level" }, [
                              _c("div", { staticClass: "level-left" }, [
                                _c("div", { staticClass: "field is-grouped" }, [
                                  _c("div", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        class: [
                                          "button is-accent",
                                          !_vm.$hasPermissions(
                                            _vm.clientSession,
                                            ["REPORT_TYPES"],
                                            2
                                          )
                                            ? "disabled"
                                            : "",
                                        ],
                                      },
                                      [
                                        _vm._m(0),
                                        _c(
                                          "span",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "has-text-white",
                                                attrs: {
                                                  to: {
                                                    name: !_vm.$hasPermissions(
                                                      _vm.clientSession,
                                                      ["REPORT_TYPES"],
                                                      2
                                                    )
                                                      ? ""
                                                      : "NewReportType",
                                                    params: {},
                                                  },
                                                },
                                              },
                                              [_vm._v("Add Report Type")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        class: [
                                          "button is-dark",
                                          !_vm.$hasPermissions(
                                            _vm.clientSession,
                                            ["REPORT_TYPES"],
                                            2
                                          )
                                            ? "disabled"
                                            : "",
                                        ],
                                        attrs: { disabled: _vm.isBtnDisabled },
                                        on: {
                                          click: function ($event) {
                                            !_vm.$hasPermissions(
                                              _vm.clientSession,
                                              ["REPORT_TYPES"],
                                              2
                                            )
                                              ? null
                                              : _vm.confirmDelete()
                                          },
                                        },
                                      },
                                      [
                                        _vm._m(1),
                                        _c("span", [
                                          _vm._v(
                                            "Delete Report Type" +
                                              _vm._s(
                                                _vm.hasPluralSelection
                                                  ? "s"
                                                  : ""
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "kendo-grid",
                      {
                        ref: "typeGrid",
                        style: {
                          maxHeight: _vm.maxHeight + "px",
                          height: _vm.maxHeight + "px",
                        },
                        attrs: {
                          id: "grid",
                          "persist-selection": true,
                          "data-source": _vm.reportTypeItems,
                          "selected-field": _vm.selectedField,
                          "pageable-always-visible": false,
                          sortable: false,
                          pageable: false,
                          total: _vm.totalRecords,
                          change: _vm.onSelectionChange,
                        },
                      },
                      [
                        _c("kendo-grid-column", {
                          attrs: {
                            selectable: true,
                            field: "selected",
                            width: 28,
                          },
                        }),
                        _c("kendo-grid-column", {
                          attrs: {
                            field: "name",
                            title: "Name",
                            width: 300,
                            template: _vm.linkTemplate,
                          },
                        }),
                        _c("kendo-grid-column", {
                          attrs: { field: "code", title: "Report Type" },
                        }),
                        _c("kendo-grid-column", {
                          attrs: { field: "category", title: "Category" },
                        }),
                        _c("kendo-grid-column", {
                          attrs: {
                            template: _vm.checkboxTemplate,
                            field: "isPersistent",
                            title: "Do Not Group By Year",
                          },
                        }),
                        _c("kendo-grid-column", {
                          attrs: { field: "order", hidden: true },
                        }),
                        _c("kendo-grid-column", {
                          attrs: { field: "createdAt", hidden: true },
                        }),
                        _c("kendo-grid-column", {
                          attrs: { field: "updatedAt", hidden: true },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.showDeleteModal
                  ? _c(
                      "div",
                      {
                        staticClass: "modal is-active",
                        attrs: { id: "delete-modal" },
                      },
                      [
                        _c("div", {
                          staticClass: "modal-background",
                          on: {
                            click: function ($event) {
                              _vm.showDeleteModal = false
                            },
                          },
                        }),
                        _c("div", { staticClass: "modal-card" }, [
                          _c(
                            "header",
                            { staticClass: "modal-card-head has-bg-danger" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "modal-card-title has-bg-danger",
                                },
                                [_vm._v("Confirm Delete")]
                              ),
                              _c("a", {
                                staticClass: "delete",
                                attrs: { "aria-label": "close" },
                                on: {
                                  click: function ($event) {
                                    _vm.showDeleteModal = false
                                  },
                                },
                              }),
                            ]
                          ),
                          _c("section", { staticClass: "modal-card-body" }, [
                            _vm._m(2),
                            _c("div", { staticClass: "field" }, [
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    { name: "focus", rawName: "v-focus" },
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.confirmationText,
                                      expression: "confirmationText",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "type `delete` and then click confirm",
                                  },
                                  domProps: { value: _vm.confirmationText },
                                  on: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.preventDefault()
                                      return _vm.deleteIfConfirmed.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.confirmationText =
                                        $event.target.value.trim()
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("footer", { staticClass: "modal-card-foot" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button is-danger",
                                attrs: {
                                  disabled: _vm.confirmationText !== "delete",
                                },
                                on: { click: _vm.deleteIfConfirmed },
                              },
                              [_vm._m(3), _c("span", [_vm._v("Confirm")])]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "button",
                                on: {
                                  click: function ($event) {
                                    _vm.showDeleteModal = false
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _vm._v(' Type " '),
      _c("b", [_vm._v("delete")]),
      _vm._v('" to confirm that you want to delete one or more items. '),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }